.ol-layerswitcher .layerswitcher-opacity {
  display: none;
}

.popup-info {
  z-index: 200;
}

.popup-info table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.popup-info td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.popup-info p {
  background-color: #2f353a;
}

.initial-map-zoom {
  pointer-events: auto;
  position: absolute;
  left: 0.5em;
  top: 4.5em;
}

.ol-search {
  top: 0;
}

.ol-scale-step-text {
  position: absolute;
  bottom: -5px;
  font-size: 12px;
  z-index: 11;
  color: #fff;
  text-shadow: unset
}

.ol-scale-singlebar {
  position: relative;
  height: 6px;
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid black;
}

.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  background-color: unset;
  float: right;
  z-index: 10;
}

.ol-custom-zoomto-div {
  top: 1.5em;
  right: 1.5em;
  z-index: 100;
}

.normal {
  mix-blend-mode: normal;
}
.multiply {
  mix-blend-mode: multiply;
}
.screen {
  mix-blend-mode: screen;
}
.none {
  filter: none;
}
.blur {
  filter: blur(2px);
}
.grayscale {
  filter: grayScale(1);
}

input[type=range] {
  width: 100%;
  margin: 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  background: #08769b;
  border: 1px solid #08769b;
  border-radius: 1px;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
  margin-top: -6px;
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #0988b3;
}
input[type=range]::-moz-range-track {
  background: #08769b;
  border: 1px solid #08769b;
  border-radius: 1px;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
input[type=range]::-moz-range-thumb {
  width: 16px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 3px;
  cursor: pointer;
}
input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 16.5px 0;
  color: transparent;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
input[type=range]::-ms-fill-lower {
  background: #076483;
  border: 1px solid #08769b;
  border-radius: 2px;
}
input[type=range]::-ms-fill-upper {
  background: #08769b;
  border: 1px solid #08769b;
  border-radius: 2px;
}
input[type=range]::-ms-thumb {
  width: 16px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type=range]:focus::-ms-fill-lower {
  background: #08769b;
}
input[type=range]:focus::-ms-fill-upper {
  background: #0988b3;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

