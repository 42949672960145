.container-fluid {
    height: calc(100% - 50px);
}

.ol-map {
    /* position: fixed;
    top: 85px;
    bottom: 50px;
    left: 0;
    right: 0; */
    height: 500px;
}

.ol-map-admin {
    /* min-height: 500px;
    height: 500px;
    width: 100%; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.ol-control {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px;
}

.ol-full-screen {
    top: .5em;
    right: .5em;
}

.ol-mouse-position-div {
    position: absolute;
    top: unset;
    right: unset;
    bottom: -1.25em;
    margin: auto;
    right: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    z-index: 100;
    display: inline-flex;

}

.ol-mouse-position {
    position: absolute;
    width: 16em;
    top: 0;
    right: unset;
    font-size: x-small;
    height: calc(1.5em + 0.75rem + 1px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
}

.ol-overviewmap {
    left: 0.5em;
    bottom: 2.5em;
}

.ol-layerswitcher .layerswitcher-progress {
    width: 180px;
}

.ol-layerswitcher .panel li label {
    max-width: 15em;
}

.ol-layerswitcher .panel li {
    max-width: 15em;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border-radius: 50%;
    border: 5px solid rgba(133, 150, 157, 0.8);
    border-top-color: rgba(3, 118, 153, 0.8);
    animation: spinner 0.6s linear infinite;
}

.ol-control.ol-layerswitcher-image {
    position: absolute;
    left: 0.5em;
    right: unset;
    text-align: left;
    bottom: 3em;
    top: unset;
    transition: all 0.2s ease 0s;
    -webkit-transition: all 0.2s ease 0s;
}

.ol-control.ol-layerswitcher-image.ol-collapsed {
    top: unset;
    transition: none;
}

.ol-layerswitcher-image.ol-collapsed .panel {
    display: block;
}

.ol-layerswitcher-image.ol-collapsed button {
    display: none;
    position: relative;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 220px;
  }
  .ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
  }
  .ol-popup-closer:after {
    content: "X";
  }